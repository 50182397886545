<template>
  <div class="navigation">
    <button class="back-button" @click="$emit('back')"><i class="fa fa-angle-left"></i>Vissza</button>
    <transition name="fade" mode="out-in">
      <div class="step-name" :key="stepName">{{ stepName }}</div>
    </transition>
    <div class="toolbar">
      <div class="button-group">
        <input type="color" class="color-picker" :value="color" @input="$emit('update:color', $event.target.value)">
      </div>

      <div class="button-group">
        <button v-for="(width, index) in lineWidthOptions"
                :key="'lw-' + index"
                :class="{ 'line-width': true, ['lw-' + index]: true, active: lineWidth === width }"
                @click="$emit('update:lineWidth', width)">
          <span></span>
        </button>
      </div>

      <div class="button-group">
        <button :disabled="!historyButtons.undo" @click="$emit('undo')"><i class="fas fa-undo"></i></button>
        <button :disabled="!historyButtons.redo" @click="$emit('redo')"><i class="fas fa-redo"></i></button>
      </div>

      <div class="button-group">
        <button @click="$emit('clear')"><i class="fas fa-trash"></i></button>
      </div>
    </div>
    <button class="next-button" @click="$emit('next')">Tovább<i class="fa fa-angle-right"></i></button>
  </div>
</template>

<script>
export default {
  name: 'Navigation',
  props: {
    stepIndex: Number,
    stepCount: Number,
    stepName: String,
    color: String,
    lineWidth: Number,
    historyButtons: Object,
  },
  data() {
    return {
      lineWidthOptions: [20, 50, 100]
    }
  }
}
</script>

<style scoped>
.navigation {
  display: grid;
  grid-template-columns: 120px auto 120px;
  grid-template-rows: 35px 35px 35px;
  grid-template-areas:
    "back empty next"
    "step-name step-name step-name"
    "tools tools tools";
  align-content: center;
  gap: 5px;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
}

.back-button {
  grid-area: back;
  justify-self: left;
}
.back-button svg {
  font-size: 1.4em;
  margin: 0 5px -3px 0;
}

.next-button {
  grid-area: next;
  justify-self: right;
}
.next-button svg {
  font-size: 1.4em;
  margin: 0 0 -3px 5px;
}

.step-name {
  grid-area: step-name;
  text-align: center;
  align-self: center;
  font-size: 1em;
}

.toolbar {
  grid-area: tools;
  display: flex;
  justify-content: center;
}

.button-group {
  display: inline-flex;
  margin: 0 8px;
}

.toolbar button {
  display: block;
  width: 30px;
  height: 30px;
  margin: 0 3px;
  padding: 0;
  color: #2c3e50;
}
.toolbar button.active {
  color: #269651;
}
.toolbar button:disabled {
  color: #adb5bd;
}

.color-picker {
  display: block;
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 5px;
  border: 0;
  cursor: pointer;
}

.line-width span {
  display: block;
  width: 18px;
  height: 2px;
  margin: 0 auto;
  background-color: #2c3e50;
  border-radius: 10px;
}
.line-width.active span {
  background-color: #269651;
}
.line-width.lw-0 span {
  height: 3px;
}
.line-width.lw-1 span {
  height: 5px;
}
.line-width.lw-2 span {
  height: 7px;
}

@media screen and (min-width: 480px) {
  .navigation {
    grid-template-columns: 120px auto 120px;
    grid-template-rows: 30px 30px;
    grid-template-areas:
    "back step-name next"
    "back tools next";
  }

  .step-name {
    font-size: 1.2em;
  }
}
</style>
