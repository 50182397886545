<template>
  <div class="canvas">
    <canvas ref="canvas"
            @mousedown="startDrawing"
            @mouseup="stopDrawing"
            @mouseleave="stopDrawing"
            @mousemove="drawLine"
            @touchstart="startDrawing"
            @touchstop="stopDrawing"
            @touchcancel="stopDrawing"
            @touchend="stopDrawing"
            @touchmove="drawLine"
            @contextmenu="$event.preventDefault()"
    />
  </div>
</template>

<script>
const imageSize = [3508, 2480];

function getMousePosition(canvas, event) {
  const rect = canvas.getBoundingClientRect()
  const scale = {
    x: rect.width / imageSize[0],
    y: rect.height / imageSize[1]
  }
  return {
    x: Math.round(((event.clientX || event.changedTouches[0].clientX) - rect.left) / scale.x),
    y: Math.round(((event.clientY || event.changedTouches[0].clientY) - rect.top) / scale.y)
  }
}

export default {
  name: 'Canvas',
  components: {
  },
  props: {
    step: Object,
    pngImage: String,
    drawingOptions: Object
  },
  data() {
    return {
      ctx: null,
      isDrawing: false,
      bgImg: null,
      undoStack: [],
      redoStack: [],
      currentLine: {
        isClear: false,
        color: '',
        width: 0,
        points: []
      },
      contourImg: new Image(),
    }
  },
  computed: {
    canUndo() { return this.undoStack.length > 0 },
    canRedo() { return this.redoStack.length > 0 },
  },
  watch: {
    undoStack(newValue) { this.$emit('update:canUndo', newValue.length > 0) },
    redoStack(newValue) { this.$emit('update:canRedo', newValue.length > 0) },
  },
  methods: {
    onResize() {
      this.$emit('setHeight', this.$refs.canvas.clientHeight)
    },
    getImgUrl(imgName) {
      const images = require.context('../assets/', false, /\.png$/)
      return images('./' + imgName + ".png")
    },
    fillBackground() {
      if (this.bgImg) {
        this.ctx.drawImage(this.bgImg, 0, 0, this.bgImg.width, this.bgImg.height)
      }
      else {
        this.ctx.fillStyle = '#ffffff'
        this.ctx.fillRect(0,0, this.$refs.canvas.width, this.$refs.canvas.height)
      }
    },
    drawContour() {
      this.ctx.drawImage(this.contourImg, 0, 0, this.contourImg.width, this.contourImg.height)
    },
    startDrawing(e) {
      e.preventDefault()
      if (this.isDrawing) return;

      const pos = getMousePosition(this.$refs.canvas, e)
      this.isDrawing = true
      this.redoStack = []
      this.currentLine = {
        color: this.$props.drawingOptions.color,
        width: this.$props.drawingOptions.lineWidth,
        points: [pos]
      }
      this.beginPath()
    },
    stopDrawing() {
      if (!this.isDrawing) return;
      this.isDrawing = false
      this.closePath()
      this.undoStack.push(this.currentLine)
    },
    drawLine(e) {
      if (!this.isDrawing) return;

      const pos = getMousePosition(this.$refs.canvas, e)
      this.currentLine.points.push(pos)
      this.lineTo(pos)
      this.drawContour()
    },
    beginPath(line = this.currentLine) {
      const pos = line.points[0]
      this.ctx.lineJoin = 'round'
      this.ctx.lineCap = 'round'
      this.ctx.strokeStyle = line.color
      this.ctx.lineWidth = line.width
      this.ctx.beginPath()
      this.ctx.moveTo(pos.x, pos.y)
      this.ctx.lineTo(pos.x, pos.y)
      this.ctx.stroke()
    },
    closePath() {
      this.ctx.closePath()
    },
    lineTo(pos) {
      this.ctx.lineTo(pos.x, pos.y)
      this.ctx.stroke()
    },
    undo() {
      if (!this.canUndo) return;

      const item = this.undoStack.pop()
      if (!item.isClear)
        this.redoStack.push(item)

      this.fillBackground()
      this.undoStack.forEach(currentLine => {
        this.beginPath(currentLine)
        currentLine.points.forEach(this.lineTo)
        this.closePath()
      })
      this.drawContour()
    },
    redo() {
      const currentLine = this.redoStack.pop()
      if (!currentLine) return;

      this.beginPath(currentLine)
      currentLine.points.forEach(this.lineTo)
      this.closePath()
      this.drawContour()

      this.undoStack.push(currentLine)
    },
    clear() {
      this.bgImg = null
      this.$emit('update:pngImage', null)
      if (this.canUndo)
        this.undoStack.push({ isClear: true })
      this.redoStack = []
      this.fillBackground()
      this.drawContour()
    },
    toPng() {
      const png = this.$refs.canvas.toDataURL("image/png")
      this.$emit('update:pngImage', png)
    }
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  mounted() {
    this.$refs.canvas.width = imageSize[0]
    this.$refs.canvas.height = imageSize[1]
    this.ctx = this.$refs.canvas.getContext("2d")
    this.contourImg.src = this.getImgUrl(this.$props.step.name)
    this.contourImg.onload = this.drawContour
    this.fillBackground()
    this.onResize()

    if (this.pngImage) {
      this.bgImg = new Image();
      this.bgImg.src = this.pngImage;
      this.bgImg.onload = () => this.ctx.drawImage(this.bgImg, 0, 0, this.bgImg.width, this.bgImg.height)
    }
  }
}
</script>

<style scoped>
.canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

canvas {
  width: 100%;
  cursor: crosshair;
  border-radius: 5px;
}
</style>
