<template>
  <div id="app">
    <transition name="slide-fade" mode="out-in" appear>
      <Welcome v-if="activeView === 'welcome'" @start="showDrawer" />
      <Drawer v-if="activeView === 'drawer'" @cancel="showWelcome" @finish="showSubmit" />
      <Submit v-if="activeView === 'submit'" :images="images" @cancel="showWelcome" @done="showWelcome" />
    </transition>
  </div>
</template>

<script>
import Welcome from './components/Welcome'
import Drawer from './components/Drawer'
import Submit from './components/Submit'

export default {
  name: 'App',
  components: {
    Welcome,
    Drawer,
    Submit
  },
  data() {
    return {
      activeView: 'welcome',
      images: []
    }
  },
  methods: {
    showDrawer() {
      this.activeView = 'drawer'
    },
    showWelcome() {
      this.activeView = 'welcome'
    },
    showSubmit(images) {
      this.images = images
      this.activeView = 'submit'
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin: 20px 10px;
}

a {
  color: #269651;
}

h3 {
  margin: 0;
}

input, button {
  padding: 10px 15px;
  background: none;
  border: none;
  border-radius: 4px;
  outline: none;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 1em;
}
button, input[type="button"], input[type="submit"] {
  cursor: pointer;
  color: #269651;
}
button.fill, input[type="button"].fill, input[type="submit"].fill {
  color: white;
  background-color: #269651;
}
button.fill:disabled, input[type="button"].fill:disabled, input[type="submit"].fill:disabled {
  color: white;
  background-color: #7cc096;
}
button.big, input[type="button"].big, input[type="submit"].big {
  font-size: 1.4em;
}

input {
  width: 100%;
  margin: 5px 0 15px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
input[type="text"], input[type="email"] {
  border: 1px solid #d0d0d0;
}
input[type="text"]:disabled, input[type="email"]:disabled {
  color: #adb5bd;
}
input:focus {
  border: 1px solid #269651;
}
input:disabled, button:disabled {
  cursor: default;
}

.page {
  width: 100%;
  max-width: 1000px;
  margin: 20px auto;
  padding: 0;
}

.page-narrow {
  width: 100%;
  max-width: 800px;
  margin: 0 auto 80px;
  padding: 0;
  background-color: white;
  border-radius: 5px;
}

.page-container {
  padding: 20px;
}

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all .3s ease;
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: all .3s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.slide-lr-enter-active, .slide-lr-leave-active,
.slide-rl-enter-active, .slide-rl-leave-active {
  transition: transform 1s ease;
}
.slide-rl-enter, .slide-lr-leave-to {
  transform: translateX(110%);
}
.slide-rl-leave-to, .slide-lr-enter {
  transform: translateX(-110%);
}

@media screen and (min-width: 480px) {
  .page-narrow {
    margin: 20px auto 0;
  }

  .page-container {
    padding: 40px;
  }
}
</style>
