<template>
  <div class="page">
    <Navigation :step-name="stepLabel"
                :step-index="stepIndex"
                :step-count="steps.length"
                :color.sync="drawingOptions.color"
                :lineWidth.sync="drawingOptions.lineWidth"
                :history-buttons="historyButtons"
                @back="back"
                @next="next"
                @undo="undo"
                @redo="redo"
                @clear="clear"
    />
    <div class="canvas-container" :style="{ height: canvasHeight + 'px' }">
      <transition :name="transitionName" @after-enter="prevStepIndex = stepIndex">
        <Canvas ref="canvas"
                :key="stepIndex"
                :step="step"
                :png-image.sync="steps[stepIndex].image"
                :drawingOptions="drawingOptions"
                :can-undo.sync="historyButtons.undo"
                :can-redo.sync="historyButtons.redo"
                @setHeight="setCanvasHeight"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import Navigation from './Navigation'
import Canvas from './Canvas'

export default {
  name: 'Drawer',
  components: {
    Canvas,
    Navigation
  },
  data() {
    return {
      steps: [
        {
          name: 'turul',
          label: 'Színezd ki a Turul madarat!',
          image: null
        },
        {
          name: 'zaszlo',
          label: 'Színezd ki az 56-os forradalmi magyar zászlót!',
          image: null
        }
      ],
      stepIndex: 0,
      prevStepIndex: 0,
      canvasHeight: 0,
      drawingOptions: {
        color: '#0000ff',
        lineWidth: 50
      },
      historyButtons: {
        undo: false,
        redo: false
      }
    }
  },
  computed: {
    step() {
      return this.steps[this.stepIndex]
    },
    stepLabel() {
      return this.steps[this.stepIndex].label
    },
    transitionName() {
      return this.stepIndex < this.prevStepIndex ? 'slide-lr' : 'slide-rl'
    }
  },
  methods: {
    setCanvasHeight(height) {
      this.canvasHeight = height
    },
    back() {
      this.$refs.canvas.toPng()

      if (this.stepIndex <= 0) {
        this.$emit('cancel')
        return
      }
      this.stepIndex--

      this.historyButtons = {
        undo: false,
        redo: false
      }
    },
    next() {
      this.$refs.canvas.toPng()

      if (this.stepIndex >= (this.steps.length - 1)) {
        this.$emit('finish', this.steps)
        return
      }

      this.stepIndex++

      this.historyButtons = {
        undo: false,
        redo: false
      }
    },
    undo() {
      this.$refs.canvas.undo()
    },
    redo() {
      this.$refs.canvas.redo()
    },
    clear() {
      this.$refs.canvas.clear()
    }
  }
}
</script>

<style scoped>
.canvas-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-top: 20px;
  border-radius: 5px;
}
</style>
