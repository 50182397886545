<template>
  <div class="page-narrow">
    <div class="page-container">
      <div v-if="view === 'form'" class="form-view">
        <div class="preview">
          <img v-for="image in images" :key="image.name" :src="image.image" />
        </div>

        <form @submit="submit">
          <p v-if="isError" class="error-message">Hiba történt, próbáld újra később.</p>

          <label for="name">Név (nem kötelező):</label>
          <input type="text" id="name" name="name" v-model="name" :disabled="disabled" />

          <label for="email">Email cím:</label>
          <input type="email" id="email" name="email" v-model="email" :disabled="disabled" />

          <input type="submit" class="fill" :disabled="disabled || submitDisabled" value="Küldés" />
          <input type="button" @click="$emit('cancel')" value="Mégse" />
        </form>
      </div>

      <div v-if="view === 'submitted'" class="form-view">
        <p>Sikeresen elküldted a rajzaidat!</p>
        <button class="big" @click="$emit('done')">Újrakezdés</button>
      </div>
    </div>
  </div>
</template>

<script>
function dataURItoBlob(dataURI) {
  const byteString = atob(dataURI.split(',')[1])
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ab], { type: mimeString })
}

export default {
  name: 'Submit',
  props: {
    images: Array
  },
  data() {
    return {
      view: 'form',
      loading: false,
      isError: false,
      name: '',
      email: '',
    }
  },
  computed: {
    disabled() {
      return this.loading
    },
    submitDisabled() {
      return !this.email
    }
  },
  methods: {
    async submit(e) {
      e.preventDefault();

      this.loading = true
      this.isError = false

      const formData  = new FormData()
      formData.append('name', this.name)
      formData.append('email', this.email)
      this.images.forEach(step => formData.append('images', dataURItoBlob(step.image), step.name))

      try {
        const response = await fetch('/api/submit', {
          method: 'POST',
          body: formData
        })
        const result = await response.json()
        result.success ? this.view = 'submitted' : this.isError = true
      }
      catch (e) {
        this.isError = true
      }

      this.loading = false
    }
  }
}
</script>

<style scoped>
.form-view {
  text-align: center;
}

.preview {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.preview img {
  width: 200px;
  margin: 10px;
}

form {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  text-align: left;
}

.error-message {
  color: #F65039;
}

@media screen and (min-width: 480px) {
  .preview {
    flex-direction: row;
  }
}
</style>
