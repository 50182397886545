<template>
  <div class="page-narrow">
    <div class="page-container">
      <p>Kedves színező!</p>

      <div class="text-picture text-picture-right">
        <p>Színezd ki nemzeti jelképünket a Turul madarat tetszésed szerint, egészítsd ki a rajzot, a karmai közé rajzolj valamit mindenképpen. Helyezd el a madarat térben, rajzolhatsz alá és fölé is.</p>
        <img src="../assets/turul_small.png">
      </div>

      <div class="text-picture text-picture-left">
        <p>Az 56-os forradalmi lyukas magyar zászlót színezd ki és rajzold bele, mit tennél a zászló közepébe. Lényeg, hogy hagyj nyomot a lapon ami önkifejezésed, vélemény nyilvánításod és fantáziád eredménye.</p>
        <img src="../assets/zaszlo_small.png">
      </div>

      <p>Alkotásod küldd be nekünk, a színezők rajzaiból egy válogatást készítünk, ha megadod e-mail címed, erről értesítünk. Színezéseddel egy iskolai feladat megvalósulását segíted. Köszönjük a részvételt az anonim színezők csoportja nevében!</p>

      <p>További kérdés esetén írj: <a href="mailto:palanna21@gmail.com">palanna21@gmail.com</a></p>

      <div class="start-button">
        <button class="big" @click="$emit('start')">Kezdés</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Welcome'
}
</script>

<style scoped>
.page-container {
  padding-top: 4px;
  padding-bottom: 40px;
}

.text-picture {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  grid-template-areas:
      "text"
      "picture";
  gap: 20px;
  align-items: center;
  justify-items: center;
  margin: 20px 0;
}

.text-picture p {
  grid-area: text;
  margin: 0;
}

.text-picture img {
  grid-area: picture;
}

.start-button {
  margin-top: 30px;
  text-align: center;
}

@media screen and (min-width: 480px) {
  .page-container {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .text-picture {
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    gap: 40px;
  }

  .text-picture.text-picture-left {
    grid-template-areas: "picture text";
  }

  .text-picture.text-picture-right {
    grid-template-areas: "text picture";
  }
}
</style>
